import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserLogout } from '@ui/legacy-lib';

@Component({
  selector: 'app-logout',
  template: '',
  standalone: true
})
export class LogoutComponent implements OnInit {
  private store = inject(Store);

  ngOnInit() {
    const redirectUri = `${window.location.origin}/login`;
    this.store.dispatch(new UserLogout(redirectUri));
  }
}
