import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { jwtDecode } from 'jwt-decode';

import {
  ERR_INVALID_NONCE,
  ERR_INVALID_STATE,
  ERR_MISSING_TOKEN,
  UserLoginFail,
  UserLoginSuccess
} from '@ui/legacy-lib';

import { AuthToken } from '@ui/legacy-lib';

import { IDToken, StateAfterAuth } from '@ui/shared/models';

@Component({
  selector: 'app-auth',
  template: '',
  standalone: true
})
export class AuthComponent implements OnInit {
  private router = inject(Router);
  private store = inject(Store);

  private getQueryParamsAsObject<T>(queryParamsString: string): T {
    return queryParamsString.split('&').reduce((asObject, currentPair) => {
      const splitted = currentPair.split('=');
      asObject[splitted[0]] = decodeURIComponent(splitted[1]);
      return asObject;
    }, {} as T);
  }

  ngOnInit() {
    const params = this.router.url.split('auth?').pop().split('#');
    const stateAfterAuth: StateAfterAuth = this.getQueryParamsAsObject(
      params.shift()
    );
    const paramsAsObject: AuthToken = this.getQueryParamsAsObject(params.pop());

    if (paramsAsObject.error) {
      return this.store.dispatch(new UserLoginFail(paramsAsObject.error));
    }

    if (!paramsAsObject.access_token || !paramsAsObject.id_token) {
      return this.store.dispatch(new UserLoginFail(ERR_MISSING_TOKEN));
    }

    const nonce_sent = sessionStorage.getItem('nonce');
    const state_sent = sessionStorage.getItem('state');
    const idDecoded: IDToken = jwtDecode(paramsAsObject.id_token);

    if (nonce_sent !== idDecoded.nonce) {
      return this.store.dispatch(new UserLoginFail(ERR_INVALID_NONCE));
    }

    if (state_sent !== paramsAsObject.state || state_sent !== idDecoded.nonce) {
      return this.store.dispatch(new UserLoginFail(ERR_INVALID_STATE));
    }

    return this.store.dispatch(
      new UserLoginSuccess(paramsAsObject, stateAfterAuth)
    );
  }
}
